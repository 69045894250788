import { ConnectedUser } from "../dto/connected-user.dto";
import { Socket } from "socket.io-client";
import { useState } from "react";
import { Trans } from "react-i18next";
import CommunityWidget from "./community-widget";
import ChatWidget from "./chat-widget";
import ChatMessage from "../dto/chat-message.dto";

export interface RightBarProps{
    chatMessages: ChatMessage[];
    users: ConnectedUser[];
    me: ConnectedUser;
    chatEnabled: boolean;
    socket: Socket;
    className: string;
    forceShowCommunityWidget?: boolean;
} 

export function RightBar(props: RightBarProps) {
    const [showCommunityWidget, setShowCommunityWidget] = useState(false); 
    const [showChat, setShowChat] = useState(true); 

    const switchCommunityWidget = () => {
        setShowChat(false);
        setShowCommunityWidget(true);
    }

    const switchChatWidget = () => {
        setShowChat(true);
        setShowCommunityWidget(false);
    }

    const onClickAlert =  (user : ConnectedUser) => { 
        props.socket.emit("user-alert", {socketID: user.socketID});
    }
    
    const onClickBan = (user: ConnectedUser) => {
        props.socket.emit("user-ban", {socketID: user.socketID});
    }

    const onClickInvite= (user: ConnectedUser) => {
        props.socket.emit("user-invite", {socketID: user.socketID});
    }

    const onClickPromote= ( user: ConnectedUser) => { 
        props.socket.emit("user-promote",  {socketID: user.socketID, role: user.role == 0 ? 1 : 0});
    }

    
    return <div className={`studio-right-bar ${props.className}`}>
    { props.chatEnabled && <div className="studio-right-bar-menu" style={{textTransform: "uppercase", display: 'flex', justifyContent: 'space-evenly', marginBottom: 20}}>
        <a style={{cursor: "pointer", fontWeight: showChat ? 'bolder': 'normal', fontSize: 16 }} onClick={ () => { switchChatWidget() }}>
            <Trans i18nKey="studio.right_bar.live_chat">
                Live Chat
            </Trans>
        </a>
        <a style={{cursor: "pointer", fontSize: 16, fontWeight: showCommunityWidget ? 'bolder': 'normal'}} onClick={ () => { switchCommunityWidget() }}>
            <Trans i18nKey="studio.right_bar.community">
                Community 
            </Trans>
            { props.me.role==2 && props.users.filter( (user) => user.hand == true ).length > 0 && <span style={{width: 10, height: 10, display: "inline-block", borderRadius: 20, backgroundColor: "red"}}>&nbsp;</span>}
        </a>
    </div> }
    {  (props.forceShowCommunityWidget || showCommunityWidget || !props!.chatEnabled)  && 
                <CommunityWidget currentUser={props.me!} users={props.users} 
                    onClickAlert={onClickAlert}
                    onClickBan={onClickBan}
                    onClickInvite={onClickInvite}
                    onClickPromote={onClickPromote}/> }
    { (props!.chatEnabled && showChat && !props.forceShowCommunityWidget) &&  <ChatWidget chatMessages={props.chatMessages} chatClient={props.socket}  currentUser={props.me!} users={props.users} 
                    onClickAlert={onClickAlert}
                    onClickBan={onClickBan}
                    onClickInvite={onClickInvite}
                    onClickPromote={onClickPromote}/> }             
</div>
}