import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StudioBarButton from "./StudioBarButton";
import { faEye,faHand, faFlag, faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faPlay, faStop, faDisplay, faDoorOpen, faFileExcel, faRightFromBracket, faCircleXmark, faSkull, faBell, faComments, faUsers, faUsersSlash } from '@fortawesome/free-solid-svg-icons'
import { Socket } from "socket.io-client";
import { ConnectedUser } from "../dto/connected-user.dto";
import { EventDTO, EventState } from "../../event/dto/event.dto";
import useModal from "../../../theme/hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import socketContext from "../context/socket-context";
import zoomContext from "../context/zoom-context";
import { useNavigate } from "react-router-dom";
import ReportModal from "./report-modal";
import FormInput from "../../../theme/components/form-input";
import { Input } from "reactstrap";
import MessageModal from "./send-message-modal";
import { t } from "i18next";

export interface ActionBarProps{
    audioEnabled: boolean;
    cameraEnabled: boolean;
    shareEnabled: boolean;
    event: EventDTO;
    me: ConnectedUser;
    socket: Socket;
    toggleAudio: Function;
    toggleCamera: Function;
    toggleShare: Function;
    toggleCommunity?: Function;
    setShowStartModal: Function;
    chatEnabled?: boolean;
    className?: string; 
    direction?: "row" | "column";
    showShare?: boolean;
    showCommunity?: boolean;
}

export default function ActionBar(props: ActionBarProps) {
    const modal = useModal();
    const [screenShareAvailable, setScreenShareAvailable] = useState(false);
    useEffect( () => {
        setScreenShareAvailable(navigator.mediaDevices.getDisplayMedia !== undefined);
    }, []);
    const zoomClient = useContext(zoomContext)
    const socket = useContext(socketContext);
    const navigate = useNavigate();

    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);

    return <div className={props.className}>
        <ReportModal onReportDone={ () => {setShowReportModal(false)}} visible={showReportModal} />
        <MessageModal onMessageDone={ () => {setShowMessageModal(false)}} visible={showMessageModal} />

        <div style={{textAlign: 'center', flexDirection: props.direction ?  props.direction : "row", display: "flex", justifyItems: "center", justifyContent: "center", alignContent: 'center', marginTop: 10}}>
        { props.event && (props.event?.isOwner || props.me?.invited) &&<>

            { props.chatEnabled && <StudioBarButton className="community" onClick={ () => { props.toggleCommunity && props.toggleCommunity(); }}>
                {props.showCommunity && <FontAwesomeIcon icon={faUsersSlash} style={{height: "50%"}} /> }
                {!props.showCommunity && <FontAwesomeIcon icon={faUsers} style={{height: "50%"}} /> }
            </StudioBarButton> }

            <StudioBarButton onClick={ () => { props.toggleAudio(); }}>
                {props.audioEnabled && <FontAwesomeIcon icon={faMicrophone} style={{height: "50%"}} /> }
                {!props.audioEnabled && <FontAwesomeIcon icon={faMicrophoneSlash} style={{height: "50%"}} /> }
            </StudioBarButton>


            <StudioBarButton onClick={ () => { props.toggleCamera() }}>
                {props.cameraEnabled && <FontAwesomeIcon icon={faVideo} style={{height: "50%"}} /> }
                {!props.cameraEnabled && <FontAwesomeIcon icon={faVideoSlash} style={{height: "50%"}} /> }
            </StudioBarButton>

            { props.showShare && screenShareAvailable && (props.me.role == 2 || props.me.visible) && <StudioBarButton onClick={ () => { props.toggleShare() }} className="share" backgroundColor={props.shareEnabled ? "#05C19E": "#272727"}>
                {props.shareEnabled && <FontAwesomeIcon icon={faDisplay} style={{height: "50%"}} /> }
                {!props.shareEnabled && <FontAwesomeIcon icon={faDisplay} style={{height: "50%"}} /> }
            </StudioBarButton>}
            </>}

            { props.event && props.event.isOwner && <>
            <StudioBarButton backgroundColor="red" onClick={ () => { if (props.event.state != EventState.Live){
                props.setShowStartModal(true);
            }else {
                props.setShowStartModal(true);
            } }}>
                {props.event.state == EventState.Live && <FontAwesomeIcon icon={faRightFromBracket} style={{height: "50%"}} /> }
                {props.event.state != EventState.Live && <FontAwesomeIcon icon={faPlay} style={{height: "50%"}} /> }
            </StudioBarButton>
            </>}
            { props.event && props.me?.admin && <> 
            <StudioBarButton backgroundColor="red" >
                <FontAwesomeIcon icon={faSkull} style={{height: "50%"}} onClick={ () => {
                modal.show(t("studio.modals.moderate_event.title"), <>{t("studio.modals.moderate_event.content")}</>, [{
                    label: t("studio.modals.moderate_event.button_yes"),
                    onClick: () => {
                        props.socket.emit("stop-event", { eventID: props.event?._id, isModerated: true });
                    }
                },
                {
                    label: t("studio.modals.moderate_event.button_no"),
                    onClick: () => {

                    }
                }
            ])
            }} />
            </StudioBarButton>
            <StudioBarButton>
                <FontAwesomeIcon icon={faBell} style={{height: "50%"}} onClick={ () => {
                    setShowMessageModal(true);
            }} />
            </StudioBarButton>            
            </>}
            { props.event && !props.event.isOwner  && <>
            <StudioBarButton backgroundColor={props.me.hand ? "#05C19E": "#272727"}>
                <FontAwesomeIcon icon={props.me?.invited ? faCircleXmark : faHand} style={{height: "50%"}} onClick={ () => {

                    if (props.me?.invited){
                        modal.show(t("studio.modals.leave_invited.title"), <>{t("studio.modals.leave_invited.content")}</>, [{
                            label: t("studio.modals.leave_invited.button_yes"),
                            onClick: ()=> {
                                socket.emit("user-invite-leave");
                            }
                        }, {
                            label: t("studio.modals.leave_invited.button_no"),
                            onClick : () => {

                            }
                        }
                    ])
                    }
                    else if (props.me?.hand == false){
                        
                        modal.show(t("studio.modals.raise_hand.title"), <>{t("studio.modals.raise_hand.content")}</>, [{
                                label: t("studio.modals.raise_hand.button_yes"),
                                onClick: ()=> {
                                    socket.emit("raise-hand");
                                }
                            }, {
                                label: t("studio.modals.raise_hand.button_no"),
                                onClick : () => {

                                }
                            }
                        ])
                    }else {
                        props.socket.emit("raise-hand");
                    }
                }} />
            </StudioBarButton>
            <StudioBarButton>
                <FontAwesomeIcon icon={faFlag} style={{height: "50%"}} onClick={ () => {
                    setShowReportModal(true);
                }} />
            </StudioBarButton>
            <StudioBarButton backgroundColor="red" >
                <FontAwesomeIcon icon={faRightFromBracket} style={{height: "50%"}} onClick={ () => {
                    modal.show(t("studio.modals.leave_event.title"), <>{t("studio.modals.leave_event.content")}</>, [
                        {
                            label: t("studio.modals.leave_event.button_yes"),
                            onClick: () => {
                                zoomClient.leave().then(() => {
                                    navigate("/");
                                });
                            }
                        },
                        {
                            label: t("studio.modals.leave_event.button_no"),
                            onClick: () => {
                            }

                        }
                    ]);
                }} />
            </StudioBarButton>
            </>}
    </div> </div>
}