import { Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import DateFilter, { DateFilterEnum } from "../components/date-filter";
import EventImage from "../../event/components/event-image";
import moment from "moment";
import { useEffect, useState } from "react";
import { listMyEvents, listMyTransferts } from "../../event/event-slice";
import { getOverview, getStats } from "../creator-slice";
import { EventState } from "../../event/dto/event.dto";
import "./stats.css"
import { Trans } from "react-i18next";

export default function Stats() {

    
    const user = useAppSelector((state) => state.login.user);
    const stats = useAppSelector((state) => state.creator.stats);
    const currentFilter = useAppSelector((state) => state.creator.currentFilter);
    const events = useAppSelector((state) => state.events.events);
    const overview = useAppSelector((state) => state.creator.overview);
    const dispatch = useAppDispatch();
    const revenues = useAppSelector(state => state.events.revenues)
    const [filter, setFilter] = useState(DateFilterEnum.LAST_7_DAYS);

    useEffect(() => {
        if (user){
            dispatch(listMyTransferts());
            dispatch(listMyEvents({category:"all", filter: "replay"}));
            dispatch(getOverview());
            dispatch(getStats(7));
        }
      }, [user]);

    return <div>
            <Row className="secondary align-content-center" style={{borderRadius: 20,  padding: 10, textAlign:"center"}}>
                    <Col md={{size: 2}} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.stream"}>Stream</Trans></h2>
                        <h3>{overview?.liveCount}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.views"}>Views</Trans></h2>
                        <h3>{overview?.views}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_live"}>Revenu Live</Trans></h2>
                        <h3>{overview?.revenueLive.toFixed(2)}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_replay"}>Revenu Replay</Trans></h2>
                        <h3>{overview?.revenueLive.toFixed(2)}</h3>
                    </Col>
                    <Col md={2} xs={6}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey={"backoffice.creator.stats.revenue_partner"}>Revenu Affiliation</Trans></h2>
                        <h3>{overview?.revenueReplay.toFixed(2)}</h3>
                    </Col>                    
                    <Col md={2} xs={12}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase',fontWeight: 'bold'}}><Trans i18nKey={"backoffice.creator.stats.revenue_total"}>Revenu Total</Trans></h2>
                        <h3 style={{fontWeight: 'bold'}}>{overview?.revenueLive.toFixed(2)}</h3>
                    </Col>                    
                </Row>    
            

                <DateFilter active={filter} onFilter={ (filter) => {
                    setFilter(filter);
                    dispatch(getStats(filter === DateFilterEnum.ALL ? 0 : filter === DateFilterEnum.LAST_7_DAYS ? 7 : filter === DateFilterEnum.LAST_30_DAYS ? 30 : 365));
                }} />
                {stats?.map((stat) => {
                return <> <Row className="secondary align-content-center stats-contain">
                <Col md={{size: 3}} xs={12} style={{display: "flex"}}>
                    <EventImage image={stat._id.event.picture} title={stat._id.event.title} color={stat._id.event.color}/>
                </Col>
                <Col md={{size: 3}} xs={12} style={{textAlign: "center", marginTop: 10}}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>{moment(stat._id.event.date).format('DD/MM/YYYY HH:mm')}</h1>
                    <p>{stat._id.event.title.substring(0, 65)} {stat._id.event.title.length > 65 && "..." }</p>
                </Col>          
                <Col md={{size: 1}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.views"}>Inscrits</Trans></h1>
                    <p>{ stat._id.event.subscribers?.length }</p>
                </Col>                         
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_live"}>Gain live</Trans></h1>
                    <p>{ stat.totalLive.toFixed(2) }</p>
                </Col>      
                <Col md={{size: 2}} style={{textAlign: 'center', marginTop: 10}} xs={6}>
                <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans i18nKey={"backoffice.creator.stats.revenue_replay"}>Gain Replay</Trans></h1>
                    <p>{ stat.totalVOD.toFixed(2) }</p>
                </Col>                                                
            </Row>
             </>})}
        </div>
}