import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { I18nLink } from "../../../theme/components/I18nLink";



const ResetPasswordSuccess = (props : any) => {


    return <>
        <p>Password reset successfully</p>
       <I18nLink to={'/login'}><Button color="primary">Login</Button></I18nLink>
    </>
};

export default ResetPasswordSuccess;
