import { Link } from "react-router-dom";
import { Button, Col, Row, Spinner } from "reactstrap";
import Loading from "./loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { listNextEventByCategory } from "../../features/event/event-slice";
import { EventCard } from "../../features/event/components/event-card";
import { Trans } from "react-i18next";
import { I18nLink } from "./I18nLink";

interface LiveSelectorProps {
    categories: any[];
    category?: string;
    filter?: string;
    loading: boolean;
    events: any[];
    states: any[];
    count?: number;
    next?: number;
    md?: number;
    lg?: number;
    xl?: number;
    hideCategories?: boolean;
    onCategoryChange: (category: string) => void;
    onStateChange: (state: string) => void;
    loadNext: (category: string, state: string, next: number) => void;
}

export function LiveSelector(props: LiveSelectorProps){

    return <>
            <Row className="tags-row">
            {!props.hideCategories && <Col md={12} className="tag-container">
                        <Link preventScrollReset={true} to={`/live/all`}><Button color={"green"} className={`tag ${(props.category != "all" && props.category != undefined) ? 'tag-disabled' : ''}`}>All</Button></Link>
                        {props.categories.map( (value, index) => {
                            const colors = ["orange", "yellow", "purple", "blue"];
                            return <Link preventScrollReset={true} to={`/live/${value._id}`}><Button className={`tag ${props.category != value._id ? 'tag-disabled' : ''}`} color={colors[index % colors.length]}>{value.name}</Button></Link>
                        })}
            </Col> }
        </Row>    
        <Row className="live-selector-row">
            <Col>
                <a onClick={ () => {
                    props.onStateChange("live");
                }} className={`selector ${props.filter && props.filter !=="live" ? 'selector-disabled' : ''}` } style={{textDecoration: !props.filter || props.filter == "live" ? "underline" : "none" }}><Trans i18nKey="home.user.in_live">Live en cours</Trans></a>
                <a onClick={() => {
                    props.onStateChange("scheduled");
                }} className={`selector ${!props.filter || props.filter !=="scheduled" ? 'selector-disabled' : ''}`} style={{textDecoration: props.filter && props.filter == "scheduled" ? "underline" : "none" }}><Trans i18nKey="home.user.live.schechuled">Live Programmés</Trans></a>
                <a onClick={() => {
                    props.onStateChange("replay");
                }} className={`selector ${!props.filter || props.filter !=="replay" ? 'selector-disabled' : ''}`} style={{textDecoration: props.filter && props.filter == "replay" ? "underline" : "none" }}><Trans i18nKey="home.user.live.replay">Replays</Trans></a>
            </Col>
        </Row>      
        { props.loading ? <Loading />: <InfiniteScroll hasMore={props.next != null} loader={<div style={{display: "flex", flex: 1, justifyContent:"center"}}><Spinner color="light"/></div>} dataLength={props.events.length} next={ () => {
                props.loadNext(props.category === undefined ? "all" : props.category, props.filter ? props.filter : "live", props.next ? props.next : 0)
            }}>
            <Row className="live-row">
            {props.events.map((event) => (
                    <Col md={props.md || 6} lg={ props.lg || 6} xl={props.xl || 4} className="live-col">
                        <I18nLink to={`/event/${event._id}`} style={{textDecoration: "none"}}>
                            <EventCard event={event} />
                        </I18nLink>
                    </Col>
            ))}  
            </Row>   

            </InfiniteScroll>
        }</>
}