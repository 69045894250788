import { Container } from "reactstrap";

import { Trans } from 'react-i18next'
import i18n from '../../i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import "./footer.css"
import { getPlaformImage } from "../../platforms/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { prefix } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { set } from "lodash";
import { I18nLink } from "./I18nLink";

const logo = getPlaformImage("logo.svg");
const logoMobile = getPlaformImage("logo-notext.svg");



export function Footer(){

    const location = useLocation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState("en");
    
    useEffect(() => {
        if (location.pathname.startsWith("/fr")) {
            setLanguage("fr")
        }else {
            setLanguage("en")
        }
    }, [location.pathname]);

    return <div className="mt-auto footer">
        <Container className="footer-container">
            <div className="footer-content-logo">
                    <img className="logo-desktop" src={logo} style={{marginRight: 50}} />
                    <img className="logo-mobile" src={logoMobile} style={{marginRight: 50}} />

                    <div style={{fontSize: '22px', display: 'inline-block'}}>
                        <a style={{marginRight: '15px'}} href=""><FontAwesomeIcon icon={faInstagram} /></a><a style={{marginRight: '15px'}} href=""><FontAwesomeIcon icon={faTiktok} /></a><a href=""><FontAwesomeIcon icon={faXTwitter} /></a>
                </div>
            </div>
            <div className="footer-content-language">
                    
                    <select className="form-select footer-content-language-select" id="switch_i18n" value={language} onChange={(ev) => {
                        setLanguage(ev.target.value)
                        i18n.changeLanguage(ev.target.value).then(() => {
                            if (location.pathname.startsWith("/fr") && ev.target.value === "en") {
                                window.location.href = location.pathname.replace("/fr", "")
                            }else {
                                if (ev.target.value === "fr") {
                                    window.location.href = "/fr" + location.pathname
                                    

                                }
                            }
                        });
                        }}>
                        <option value="en"><Trans i18nKey={"common.lang.english"}>English</Trans></option>
                        <option value="fr"><Trans i18nKey={"common.lang.french"}>French</Trans></option>
                    </select>
                </div>            
            <div className="footer-links">
               <I18nLink to="/contact"><Trans i18nKey={"footer.contact"}>Contact</Trans></I18nLink>  |    <I18nLink to="/faq"><Trans i18nKey={"footer.faq"}>FAQ</Trans></I18nLink> | <I18nLink to="/legal"><Trans i18nKey={"footer.legal"}>Mentions légales</Trans></I18nLink>     |    <I18nLink to="/terms"><Trans i18nKey={"footer.terms"}>Conditions générales de vente</Trans></I18nLink>
            </div>    

        </Container>
    </div>
}