import React from 'react';
import { Col } from 'reactstrap';

export enum DateFilterEnum {
    ALL = 'all',
    LAST_7_DAYS = 'last_7_days',
    LAST_30_DAYS = 'last_30_days',
    LAST_YEAR = 'last_year',
}

export interface DateFilterProps {
    onFilter?: (date: DateFilterEnum) => void;
    active: DateFilterEnum;
    colSize?: number;
}

export const styles = {
    filter: {
        color: "#8A8A8A",
        cursor: "pointer",
    }
}

export function DateFilterItem(props: { value: DateFilterEnum, text: string, active: boolean, onClick: () => void }) {
    return (
        <a
            style={{ ...styles.filter, textDecoration: props.active ? 'underline' : 'none' }}
            onClick={props.onClick}
        >
            {props.text}
        </a>
    );
}

export default function DateFilter(props: DateFilterProps) {
    const { active, colSize = 5 } = props;

    const onFilter = (filter: DateFilterEnum) => {
        props.onFilter && props.onFilter(filter);
    };

    return (
        <Col md={colSize} style={{ listStyleType: 'none', justifyItems: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <DateFilterItem
                value={DateFilterEnum.LAST_7_DAYS}
                text="Last 7 days"
                active={active === DateFilterEnum.LAST_7_DAYS}
                onClick={() => onFilter(DateFilterEnum.LAST_7_DAYS)}
            />
            <DateFilterItem
                value={DateFilterEnum.LAST_30_DAYS}
                text="Last 30 days"
                active={active === DateFilterEnum.LAST_30_DAYS}
                onClick={() => onFilter(DateFilterEnum.LAST_30_DAYS)}
            />
            <DateFilterItem
                value={DateFilterEnum.LAST_YEAR}
                text="Last Year"
                active={active === DateFilterEnum.LAST_YEAR}
                onClick={() => onFilter(DateFilterEnum.LAST_YEAR)}
            />
            <DateFilterItem
                value={DateFilterEnum.ALL}
                text="All"
                active={active === DateFilterEnum.ALL}
                onClick={() => onFilter(DateFilterEnum.ALL)}
            />
        </Col>
    );
}