import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Page } from "../../../theme/templates/page";
import { Button, Col, Container, Row } from "reactstrap";
import EventImage from "../../event/components/event-image";
import { EventCard } from "../../event/components/event-card";
import { listAllEvents, listEventByCategory, listHomePageEvents } from "../../event/event-slice";
import { Trans, useTranslation } from "react-i18next";
import "./home.css";
import { Link as ScrollLink , animateScroll as scroll } from "react-scroll";
import { getPlaformImage } from "../../../platforms/config";
import { RightImageContent } from "../../../theme/components/right-image-content";
import { LeftImageContent } from "../../../theme/components/left-image-content";
import Loading from "../../../theme/components/loading";
import { I18nLink } from "../../../theme/components/I18nLink";


const homeImage = getPlaformImage("home-viewers.png");

export default function Home() {
    const { t } = useTranslation(); 

    const navigation = useNavigate();
    const user = useAppSelector((state) => state.login.user);
    const ready = useAppSelector((state) => state.app.ready);
    const loading = useAppSelector((state) => state.events.loading);
    const events = useAppSelector((state) => state.events.events);
    const dispatch = useAppDispatch();


    useEffect( () => {
        dispatch(listHomePageEvents());

    }, []);

    return <Page className="home landing">
        <Container>
            <Row className="header">
                <Col lg={6} className="header-left">
                    <div>
                        <h1><Trans i18nKey="home.user.header.title">Apprenez, Interagissez, Vibrez !</Trans></h1>
                        <p ><Trans i18nKey="home.user.header.description">Plongez au cœur de notre univers de live streaming où chaque session est une invitation à élargir vos horizons. 'Apprenez, Interagissez, Vibrez ! Ce n'est pas qu'un slogan, c'est une promesse : celle d'accéder à une multitude de contenus interactifs conçus pour enrichir vos connaissances et vous divertir.</Trans></p>
                        <I18nLink to="/live"><Button color="primary">
                            <Trans i18nKey="home.user.header.discover_lives_btn">
                                Découvrir les lives
                            </Trans>
                        </Button>
                        </I18nLink>
                    </div>
                </Col>
                <Col>
                    <img src={homeImage} className="header-right-img" />
                </Col>
            </Row>
            <Row className="separator">
                <h2 className="text-center explore"><Trans i18nKey={"home.user.explore"}>EXPLOREZ LES AVANTAGES UNIQUES DE NOTRE PLATEFORME</Trans></h2>
            </Row>
            <Row className="justify-content-center service-options">
                <RightImageContent title={t("home.user.live_options_title")} description={t("home.user.live_options_description")}  image={getPlaformImage('viewers/live-options.png')}/>
                <LeftImageContent title={t("home.user.multiviewer_title")}
                                description={t("home.user.multiviewer_description")}  
                                image={getPlaformImage('viewers/multiviewer.png')}/>
        
                <RightImageContent title={t("home.user.cohosting_title")} description={t("home.user.cohosting_description")}  image={getPlaformImage('viewers/revenue-share.png')}/>

                </Row>
                <Row>
                    <Col className="text-center">
                        <h2 id="discover-lives" className="discover-lives"><Trans i18nKey="home.user.discover_last_title">Les derniers lives !</Trans></h2>
                    </Col>
                </Row>                         
                <Row className="live-row">
                    { loading ? <Loading /> : events.map((event) => (
                            <Col md={6} lg={6} xl={4} className="live-col">
                                <I18nLink to={`/event/${event._id}`} style={{textDecoration: "none"}}>
                                    <EventCard event={event} />
                                </I18nLink>
                            </Col>
                    ))}
                </Row>   
                <Row className="text-center">
                    <I18nLink to={"/live"}><Button color="primary"><Trans i18nKey="home.user.all_lives">Voir les lives</Trans></Button></I18nLink>
                </Row>
                <Row className="creator-row">
                    <Col md={8} className="creator">
                        <h2><Trans i18nKey="home.creator.footer.creator_title">Devenez Créateurs</Trans></h2>
                        <p><Trans i18nKey="home.creator.footer.creator_tagline">Plongez dans le monde du streaming live où votre créativité rencontre la monétisation. Partagez votre passion tout en monétisant votre contenu.</Trans></p>
                        <I18nLink to={"/creator"} className="creator-cta"><Button color="primary"><Trans i18nKey="home.creator.footer.call_to_action">Devenez créateur</Trans></Button></I18nLink>
                        <img style={{justifySelf:"stretch"}} src={getPlaformImage('home.png')}/>
                    </Col>
                </Row>                             
                                                   
        </Container>
    </Page>
  }   