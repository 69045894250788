import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { listMyTransferts } from "../../event/event-slice";
import TransfertDto, { TransfertState } from "../../event/dto/transfert.dto";
import { Trans } from "react-i18next";
import { getOverview, getStats } from "../creator-slice";

export default function Revenues(){

    const dispatch = useAppDispatch();
    const transferts = useAppSelector(state => state.events.transferts)
    const revenues = useAppSelector(state => state.events.revenues)
    const overview = useAppSelector((state) => state.creator.overview);


    const [total, setTotal] = useState(0);
    const [totalWaiting, setTotalWaiting] = useState(0);
    const [totalDone, setTotalDone] = useState(0);

    useEffect( () => {
        dispatch(listMyTransferts());
        dispatch(getOverview());

    }, [])

    useEffect( ( ) => {
        
        let totalWaiting = 0;
        let totalDone = 0;

        for (let transfert of transferts ){
            if (transfert.state == TransfertState.Completed){
                totalDone += transfert.amount;
            }
        }

        setTotal(parseFloat(totalDone.toFixed(2)));
;
        setTotalDone(parseFloat(totalDone.toFixed(2)));
    }, [transferts])

    return <div>
            <Row className="secondary align-content-center" style={{borderRadius: 20, minHeight: 120, padding: 10}}>
                    <Col md={{size: 2, offset: 1}} xs={12} style={{borderRight: "1px solid #424242"}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey="backoffice.creator.revenue.total">Total</Trans></h2>
                        <h3>{overview?.revenueLive.toFixed(2)} $</h3>
                    </Col>
                    <Col md={{offset: 1, size: 2}} xs={12} style={{borderRight: "1px solid #424242"}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey="backoffice.creator.revenue.total_delivered">Total versé</Trans></h2>
                        <h3>{ totalDone.toFixed(2) } $</h3>
                    </Col>
                    <Col md={{offset: 1, size: 2}} xs={12} style={{borderRight: "1px solid #424242"}}>
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey="backoffice.creator.revenue.transfert_fee">Frais de transfert</Trans></h2>
                        <h3>{ overview?.transfertFee.toFixed(2) } $</h3>
                    </Col>
                    <Col md={{offset: 1, size: 2}} xs={12} >
                        <h2 style={{color: "#8A8A8A", fontSize: 12, textTransform: 'uppercase'}}><Trans i18nKey="backoffice.creator.revenue.payment_soon">Paiement à venir</Trans></h2>
                        <h3>{ revenues.toFixed(2) } $</h3>
                    </Col>           
                </Row>    
                
                <h1 style={{fontSize: 22, marginTop: 40}}><Trans i18nKey={"backoffice.creator.revenue.transferts"}>Liste des virements</Trans></h1>

                { transferts.map( ( transfert ) =>{
                    return <Row className="secondary align-content-center" style={{borderRadius: 20,  padding: 30, marginTop: 10}}>
                    <Col md={{size: 3}} style={{textAlign: 'center'}}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans  i18nKey="backoffice.creator.revenue.date">Date</Trans></h1>
                        <p>{transfert.createdAt.toString() }</p>
                    </Col>       
                    <Col md={{size: 3}} style={{textAlign: 'center'}}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans  i18nKey="backoffice.creator.revenue.amount">Amount</Trans></h1>
                        <p>{transfert.amount} $</p>
                    </Col>   
                    <Col md={{size: 3}} style={{textAlign: 'center'}}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans  i18nKey="backoffice.creator.revenue.state">State</Trans></h1>
                        <p>{transfert.state}</p>
                    </Col>      
                    <Col md={{size: 3}} style={{textAlign: 'center'}}>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}><Trans  i18nKey="backoffice.creator.revenue.state">State</Trans></h1>
                        <p>Invoice</p>
                    </Col>                                                                                                            
                </Row>
                }) }
    </div>
}