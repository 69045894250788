import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import moment from "moment"
import CreatorImage from "./creator-image"
import { Link } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./creator-header.css"
import { faInstagram, faTiktok, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faCamera, faEye, faVideo } from "@fortawesome/free-solid-svg-icons"
import { INSTAGRAM_PREFIX, TIKTOK_PREFIX, TWITTER_PREFIX, YOUTUBE_PREFIX } from "../../../common/social"
import { I18nLink } from "../../../theme/components/I18nLink"

export interface CreatorHeaderProps{
    creator: {
        userName: string,
        color?: string,
        picture?: string,
        description?: string,
        twitter?: string,
        tiktok?: string,
        instagram?: string,
        youtube?: string,
        liveCount?: number,
        viewsCount?: number;
        isLive?: boolean;
        lastLive?: Date;
    }
}

export default function CreatorHeader(props : CreatorHeaderProps){
    const [showModalMore, setShowModalMore] = useState(false); 
    const toggleModal = () => {
        setShowModalMore(!showModalMore);
    }
    return <>
    <Modal isOpen={showModalMore} toggle={toggleModal}  centered={true}>
        <ModalHeader>{props.creator.userName}</ModalHeader>
        <ModalBody>{props.creator.description}</ModalBody>
        <ModalFooter></ModalFooter>
    </Modal>
    {props.creator && <Row className="creator-header">
    <Col md={{offset:1, size:2}} xs={2}>
        <I18nLink to={`/profil/${props.creator.userName}`}><CreatorImage userName={props.creator.userName} image={props.creator.picture} isLive={props.creator.isLive} color={props.creator.color}/></I18nLink>
    </Col>
    <Col md={5}  xs={10}>
      <I18nLink to={`/profil/${props.creator.userName}`} style={{textDecoration: 'none'}}><h2 className="creator-link-title">{props.creator.userName}</h2></I18nLink>
      <p><FontAwesomeIcon style={{marginRight: 5}} icon={faVideo}/>{props.creator.liveCount ?  props.creator.liveCount : 0} lives<FontAwesomeIcon style={{marginRight: 5, marginLeft:10}} icon={faEye} />{props.creator.liveCount ?  props.creator.viewsCount : 0} vues</p>
      {props.creator.lastLive && <p>{ moment().diff(moment(props.creator.lastLive), "days") } jours</p>  }
      <p>{props.creator.description?.substring(0, 119)} {props.creator.description && props.creator.description?.length >= 118 && <a href="#" style={{fontWeight: 800}} onClick={toggleModal}>...more</a> }</p>
        {props.creator.twitter && <span style={{marginRight: 10}}><a href={TWITTER_PREFIX+props.creator.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter}/> Twitter</a> </span>}
        {props.creator.instagram && <span style={{marginRight: 10}}><a href={INSTAGRAM_PREFIX+props.creator.instagram} target="_blank"><FontAwesomeIcon icon={faInstagram}/> Instagram</a> </span>}
        <br />
        {props.creator.youtube && <span style={{marginRight: 10}}><a href={YOUTUBE_PREFIX+props.creator.youtube} target="_blank"><FontAwesomeIcon icon={faInstagram}/> Youtube</a></span>}
        {props.creator.tiktok && <span><a href={TIKTOK_PREFIX+props.creator.tiktok} target="_blank"><FontAwesomeIcon icon={faTiktok}/> Tiktok</a> </span>}

    </Col>
    
    </Row>}
    </>
}

