import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatorImage from "../../creator/components/creator-image";
import { faDotCircle, faHand } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { ConnectedUser } from "../dto/connected-user.dto";
import { UserActionDropdown } from "./user-action-dropdown";

interface UserWidgetProps{
    user : ConnectedUser
    me: ConnectedUser


    onClickPromote?: Function;
    onClickInvite?: Function;
    onClickAlert?: Function;
    onClickBan?: Function;
}

export default function UserWidget(props: UserWidgetProps){


    return <div style={{display: "flex", maxHeight: 50, marginBottom: 10, alignItems: "center"}}>
    <div style={{marginRight: 10, maxHeight: 50, maxWidth: 50}}>
        <CreatorImage userName={props.user.userName} image={props.user.picture} color={props.user.color}/>
    </div>
    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", flex: 1}}>
      <h2 style={{color: '#8A8A8A', fontSize: 16, marginBottom:0}}>{props.user.userName}</h2>
      { props.user.hand && <FontAwesomeIcon icon={faHand} style={{marginLeft: 20, color: "#7580FC", fontSize: 20}} />}  
    </div>
    
    <UserActionDropdown me={props.me} user={props.user} onClickAlert={props.onClickAlert} onClickBan={props.onClickBan} onClickInvite={props.onClickInvite} onClickPromote={props.onClickPromote} /> 
</div>
}