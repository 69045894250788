import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { list } from "../../login/login-slice";
import { Button, Col, Row, Table } from "reactstrap";
import CreatorImage from "../../creator/components/creator-image";
import moment from "moment";
import { adminListAllEvents, adminListEventByCategory, adminListNextEventByCategory, listAllEvents, listEventByCategory } from "../../event/event-slice";
import EventImage from "../../event/components/event-image";
import { Link, useParams } from "react-router-dom";
import { EventState } from "../../event/dto/event.dto";
import { I18nLink } from "../../../theme/components/I18nLink";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../../theme/components/loading";

export default function AdminEvents() {
    
    const dispatch = useAppDispatch();
    const events = useAppSelector( state => state.events.events )
    const eventCount = useAppSelector( state => state.events.eventCount )
    const eventNext = useAppSelector( state => state.events.eventNext )
    const {category, filter} = useParams();

    
    useEffect( () => {
        if (!category && !filter){
            dispatch(adminListAllEvents());
        }if (category){
            dispatch(adminListEventByCategory({category, filter}))
        }
    }, [category, filter]);


  return <div>
    <Row style={{marginTop: 30, marginBottom: 30, display: 'flex', justifyContent: "center"}}>
        <Col style={{ display: 'flex', justifyContent: 'space-evenly', maxWidth: 700}}>
                <I18nLink to={`/admin/events/${category ===undefined ? "all" : category}/live`} style={{color: "#D9D9D9", fontSize: 22, textDecoration: !filter || filter == "live" ? "underline" : "none" }}>Live en cours</I18nLink>
                <I18nLink to={`/admin/events/${category  === undefined ? "all" : category}/scheduled`} style={{color: "#D9D9D9", fontSize: 22, textDecoration: filter && filter == "scheduled" ? "underline" : "none" }}>Live Programmés</I18nLink>
                <I18nLink to={`/admin/events/${category === undefined ? "all" : category}/done`} style={{color: "#D9D9D9", fontSize: 22, textDecoration: filter && filter == "done" ? "underline" : "none" }}>Terminés</I18nLink>
                <I18nLink to={`/admin/events/${category === undefined ? "all" : category}/replay`} style={{color: "#D9D9D9", fontSize: 22, textDecoration: filter && filter == "replay" ? "underline" : "none" }}>Replays</I18nLink>
                <I18nLink to={`/admin/events/${category === undefined ? "all" : category}/canceled`} style={{color: "#D9D9D9", fontSize: 22, textDecoration: filter && filter == "canceled" ? "underline" : "none" }}>Canceled</I18nLink>
                <I18nLink to={`/admin/events/${category === undefined ? "all" : category}/moderated`} style={{color: "#D9D9D9", fontSize: 22, textDecoration: filter && filter == "moderated" ? "underline" : "none" }}>Moderated</I18nLink>
        </Col>
    </Row>         
    <Row>
        <Col>
        { eventCount == 0 && <tr><td colSpan={4}>Aucun évènement</td></tr>}
            { eventCount && <InfiniteScroll loader={<Loading />} next={ 
                    () => {
                        console.log("Next event", eventNext);
                        dispatch(adminListNextEventByCategory({category, filter, offset: eventNext}))
                    }
                } dataLength={events.length} hasMore={eventNext != null} >
            <Table dark={true} >
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Photo</th>
                        <th>Date/heure Titre</th>
                        <th>Action</th>
                    </tr>
                </thead>    
                <tbody>
                 {events.map ( (event) => {
                    return  <tr className="secondary align-content-center" style={{borderRadius: 20,  padding: 30, marginTop: 10}}>
                    <td>
                        {event.id}
                    </td>
                    <td>
                        <EventImage image={event.picture} title={event.title} color={event.color}/>
                    </td>
                    <td>
                    <h1 style={{color: "#8A8A8A", fontSize: 12, textTransform: "uppercase"}}>{moment(event.createdAt).format('DD/MM/YYYY HH:mm')}</h1>
                        <p>{event.title}</p>
                    </td>
                    <td>
                        {event.state == EventState.Live && <I18nLink to={`/studio/${event.id}`}><Button color="primary">Accéder au direct</Button></I18nLink> }
                        <I18nLink to={`/admin/event/${event.id}`}><Button color="primary">Informations sur l'évènement</Button></I18nLink>

                    </td>        

                </tr> 
                })}

                </tbody>
            </Table>
            </InfiniteScroll>      }

        </Col>

    </Row>
  </div>;
}      