import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import AuthService from "../features/login/services/auth-service";
import { PlatformConfig } from "../platforms/PlatformConfig";
import { getConfig } from "../platforms/config";

const config = getConfig();

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL =  "https://lbo-1.dev.stream.studio";
    //axios.defaults.baseURL = "http://localhost:3001";

}else {
    axios.defaults.baseURL =  config.api;
}



axios.interceptors.request.use(
    (config : InternalAxiosRequestConfig) => {
        const user = AuthService.getCurrentUser();
        console.log(user);
        if (user !== null){
            config.headers["Authorization"] = "Bearer " + (user as any).accessToken;
        }
        return config;
    },
    error => {
      Promise.reject(error);
    }
  );

export default axios;
