import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import FormInput from "../../../theme/components/form-input";
import { clearEvent, createEvent, updateThumbnail } from "../event-slice";
import { CreateEventDTO } from "../dto/create-event.dto";
import moment from "moment";
import * as yup from "yup"
import { useNavigate } from "react-router-dom";
import EventImage from "../components/event-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CategoryDTO from "../dto/category.dto";
import logger from "../../../common/logger";
import "./create-event.css"
import Info from "../../../theme/components/info";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import useModal from "../../../theme/hooks/use-modal";
import useLoading from "../../../theme/hooks/use-loading";
import { getConfig } from "../../../platforms/config";
import { Trans } from "react-i18next";
import { t } from "i18next";
import ImageCropperModal from "../../../theme/components/image-cropper-modal";

type CreateEventValues = {
  title: string,
  description: string,
  category: string,
  date: Date | string,
  chatEnabled: boolean,
  priceType: number,
  language: string,
  seats: number,
  type: number,
  time: string,
  price: number,
  isPublic: boolean
}




export default function CreateEventPage() {

  const config = useAppSelector((state) => state.app.config);
  console.log(config)
  const CreateEventSchema = yup.object({
    title: yup.string().min(2).required("Title is Required"),
    description: yup.string().min(2).required("Descritpion is Required"),
    date: yup.string().required("Date is Required").test('date', 'Date must be in the future', (value) => {
      return moment(value).isAfter(moment().days(-1));
    }),
    time: yup.string().required("Time is Required"),
    language: yup.string().required("Language is required"),
    seats: yup.number().default(980).required("Seats number is required").min(1).max(980),
    price: yup.number().positive().transform((_, value) => {
      if (value.includes('.')) {
        return parseFloat(value);
      }
      return +value.replace(/,/, '.');
    }).required("Price is required"),
    category: yup.string().required("Category is Required"),
    chatEnabled: yup.boolean().required("Chat is Required"),
    priceType: yup.number().required("Price Type is Required"),
    type: yup.number().required("Type is Required"),


  })

  const resolver = useYupValidationResolver(CreateEventSchema);
  const eventErrors = useAppSelector((state) => state.events.error);


  const { register, setValue, handleSubmit, formState: { errors } } = useForm<CreateEventValues>({resolver});
  const dispatch = useAppDispatch();
  const [submited, setSubmited] = useState(false);
  const [ startNow, setStartNow ] = useState(false);
  const loading = useLoading();
  
  const [showEditor, setShowEditor] = useState(false);
  const [cropperThumbnailURL, setCropperThumbnailURL] = useState<String>("");

  const [colorIndex, setColorIndex] = useState(0);

  const switchColor = () => {
    let nextIndex = colorIndex + 1;
    if (nextIndex >= colors.length){
      nextIndex = 0;
    }
    setColorIndex(nextIndex);
  }

  const event = useAppSelector((state) => state.events.event);
  const thumbnailUploaded = useAppSelector((state) => state.events.thumbnailUploaded);

  const onCrop = (blob: Blob) => {
    let file = new File([blob], "file.png", {type:"image/png"});
    setThumbnail(file);
    setThumbnailURL(URL.createObjectURL(blob));
    setShowEditor(false);
  }

  const categories = useAppSelector((state) => state.events.categories);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [thumbnailURL, setThumbnailURL] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [seats, setSeats] = useState(980);
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<CreateEventValues> = (data) => {
      if(!submited){
        loading.show(true);
        const event : CreateEventDTO = data;
        event.seats = seats;
        event.date = moment(`${data.date} ${data.time}`, 'YYYY-MM-DD HH:mm').toDate();
        event.isPublic = data.type === 1;
        event.paymentType = data.priceType;
        event.color = colors[colorIndex]
        dispatch(createEvent(data));
        setSubmited(true);
      }
  }

  const colors = getConfig().colors;


  const [pricingType, setPricingType] = useState(0);

  const onChangeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file){
        setCropperThumbnailURL(URL.createObjectURL(file));
        setShowEditor(true);
        
      }
  }

  const inputFile = useRef<any>(null);

  useEffect( () => {
    if (!submited && event){
      dispatch(clearEvent())

    } else if (submited && event && thumbnail && !thumbnailUploaded){
      loading.show(false);
      dispatch(updateThumbnail({id: event._id, file: thumbnail}));
    }
    else if (submited && event){
      loading.show(false);
      navigate(`/event/${event._id}`);
    }
  }, [submited, event, thumbnailUploaded]);


  useEffect( () => {
    if (errors){
      loading.show(false);
    }

    console.log(errors);
  }, [errors]);


  return <Form method="post" onSubmit={handleSubmit(onSubmit)}>
            { eventErrors && <Alert color="danger">{eventErrors}</Alert >}
            <Row>
              <h1><Trans i18nKey={"backoffice.creator.create_event.create_event"}>Créer un live</Trans></h1>
              <h2><Trans i18nKey={"backoffice.creator.create_event.schedule"}>Programmer</Trans></h2>
              <Col md={3}>
                <FormGroup>
                  <Label for="formDate">
                    <Trans i18nKey={"backoffice.creator.create_event.hour"}>Date</Trans>
                  </Label>
                  <FormInput
                    id="formDate"
                    name="date"
                    placeholder="Date"
                    register={register}
                    type="date"
                    invalid={errors.date}
                    disabled={startNow}
                  />
                  <FormFeedback>
                    { errors.date?.message }
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="formTime">
                    <Trans i18nKey={"backoffice.creator.create_event.hour"}>Heure</Trans>
                  </Label>
                  <FormInput
                    id="formTime"
                    name="time"
                    placeholder="Time"
                    type="time"
                    register={register}
                    invalid={errors.time}
                    disabled={startNow}
                  />
                  <FormFeedback>
                    { errors.time?.message }
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                ou
                <FormGroup style={{marginTop: 10}}>
                <Input
                    id="formBroadcastNow"
                    name="broadcastNow"
                    placeholder="Date"
                    type="checkbox"
                    style={{marginRight: 10}}
                    onChange={ (event) => {
                      if (event.target.checked){
                        setValue("date", moment().format("YYYY-MM-DD"), {shouldValidate: true});
                        setValue("time", moment().format("HH:mm"), {shouldValidate: true});
                        setStartNow(true);
                      }else {
                        setValue("date", moment().add(1, 'days').format("YYYY-MM-DD"))
                        setValue("time", moment().format("HH:mm"), {shouldValidate: true});
                        setStartNow(false);

                      }
                    }}
                  />
                <Label for="formBroadcastNow">
                    <Trans i18nKey={"backoffice.creator.create_event.live_now"}>Démarrer maintenant</Trans>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <h2><Trans i18nKey={"backoffice.creator.create_event.about"}>Informations</Trans></h2>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label for="title">
                    <Trans i18nKey="backoffice.creator.create_event.title">Titre</Trans>
                  </Label>
                  <FormInput
                    id="title"
                    placeholder={t("backoffice.creator.create_event.title")}
                    name="title"
                    register={register}
                    invalid={errors.title}
                    onChange={ (e) => { setTitle(e.target.value)} }
                  />
                  <FormFeedback>
                    { errors.title?.message }
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label for="exampleState">
                    <Trans i18nKey="backoffice.creator.create_event.category">Category</Trans>
                  </Label>
                  <FormInput
                    id="category"
                    name="category"
                    type="select"
                    register={register}
                    invalid={errors.category}
                  >
                      { categories.map((category : CategoryDTO) => {
                        return <option value={category._id}>{category.name}</option>
                      })}

                  </FormInput>
                  <FormFeedback>
                    { errors.category?.message }
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label for="formDescription">
                    <Trans i18nKey="backoffice.creator.create_event.describe">Description</Trans>
                  </Label>
                  <FormInput
                    id="formDescription"
                    name="description"
                    type="textarea"
                    register={register}
                    invalid={errors.description}
                    style={{height:'185px'}}

                  />
                  <FormFeedback>
                    { errors.description?.message }
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="formLanguage">
                    <Trans i18nKey="backoffice.creator.create_event.language">Langue</Trans>
                  </Label>
                  <FormInput
                    id="formLanguage"
                    name="language"
                    type="select"
                    register={register}
                    invalid={errors.language}>
                      <option value="fr">Français</option>
                      <option value="en">English</option>
                    </FormInput>
                  <FormFeedback>
                    { errors.language?.message }
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleState">
                    <Trans i18nKey="backoffice.creator.create_event.thumbnail">Thumbnail</Trans>
                  </Label>
                  <br />
                  <EventImage title={title} image={thumbnail ? thumbnailURL : ""} isPreview={true} color={colors[colorIndex]} onSwitchColor={ () => {
                    switchColor();
                  }}/>
                  <p style={{color:'#8A8A8A', width: '100%', textAlign: 'center', marginTop: 10}}><FontAwesomeIcon icon={faTriangleExclamation} /> <Trans i18nKey={"backoffice.creator.create_event.upload_advice"}>Recommanded size : 1920x1080 (16:9)</Trans></p>
                  <input type="file" id="imgupload" style={{display:"none"}}  ref={(ref) => inputFile.current = ref} onChange={ onChangeInputFile }/>
                  <br />
                  <Button color="primary" style={{}} onClick={ () => {  inputFile.current.click(); }}><Trans i18nKey={"backoffice.creator.create_event.upload"}>Uploader une image</Trans></Button>
                </FormGroup>
              </Col>
            </Row>

            <h2><Trans i18nKey={""}></Trans></h2>
            <Row>
              <Col lg={6}>
              <FormGroup switch style={{marginBottom: 20}}>
                  <Label for="chatEnabled">
                    <Trans i18nKey="backoffice.creator.create_event.enable_chat">Chat</Trans>
                  </Label>
                <FormInput
                    id="chatEnabled"
                    name="chatEnabled"
                    type="switch"
                    register={register}
                    invalid={errors.price}
                    style={{marginRight: 10}}l

                  />
                  <FormFeedback>
                    { errors.chatEnabled?.message }
                  </FormFeedback>
                </FormGroup>
                </Col>
            </Row>
            <Row>
              <Col lg={6}>
              <FormGroup>
                  <Label for="formLiveType">
                  <Trans i18nKey={"backoffice.creator.create_event.type"}>Type de diffusion</Trans><Info message={t("backoffice.creator.create_event.info_type")} />
                  </Label>
                  <FormInput
                    id="formLiveType"
                    name="type"
                    type="select"
                    register={register}
                  >
                      <option value={1}><Trans i18nKey={"backoffice.creator.create_event.public"}>Live Public</Trans></option>
                      <option value={0}><Trans i18nKey={"backoffice.creator.create_event.private"}>Live Privé</Trans></option>
                  </FormInput>
                </FormGroup>
              </Col>
              <Col lg={6}>
              <FormGroup>
                  <Label for="formSeats">
                    <Trans i18nKey={"backoffice.creator.create_event.seats"}>Nombre de places</Trans><Info message={t("backoffice.creator.create_event.info_seats")} />
                  </Label>
                  <Row>
                  <Col md={10} style={{display:"flex", alignItems:"center", justifyItems:"center"}}>
                    <Input
                      type="range"
                      register={register}
                      min={1}
                      max={980}
                      step={1}
                      value={seats}
                      onChange={ ( event )=> {
                        setSeats(parseInt(event.target.value));

                      }}
                    />
                  </Col>
                  <Col md={2}>
                  <FormInput
                    id="formSeats"
                    name="seats"
                    type="number"
                    register={register}
                    value={seats}
                    invalid={errors.seats}
                    onChange={ (event) => {
                      setSeats(event.target.value)
                    }}
                  />
                  </Col>
                  </Row>
                  <FormFeedback>
                    { errors.seats?.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <h2><Trans i18nKey={"backoffice.creator.create_event.pricing"}>Tarification</Trans></h2>
            <Row>
              <Col md={6}>
              <FormGroup>
                  <Label for="formPriceType">
                    <Trans i18nKey={"backoffice.creator.create_event.price_type"}>Type de tarification</Trans>
                  </Label>
                  <FormInput
                    id="formPriceType"
                    name="priceType"
                    type="select"
                    register={register}
                    onChange={ (e) => {
                      logger.info(`Setting pricing type ${e.target.value}`)
                      setPricingType(parseInt(e.target.value));
                    }}
                  >
                      <option value={0}><Trans i18nKey={"backoffice.creator.create_event.price_type_fixed"}>Prix fixe</Trans></option>
                      <option value={1}><Trans i18nKey={"backoffice.creator.create_event.price_type_variable"}>Prix variable</Trans></option>
                  </FormInput>

                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formPrice">
                    <Trans i18nKey={"backoffice.creator.create_event.price"}>Prix HT</Trans>
                  </Label>
                  <FormInput
                    id="formPrice"
                    name="price"
                    type="text"
                    register={register}
                    invalid={errors.price}
                  />
                  $ / { pricingType === 0 ? "Live" : "Minute"}
                  <FormFeedback>
                    { errors.price?.message }
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary">
              <Trans i18nKey={"backoffice.creator.create_event.create_button"}>Valider et obtenir le lien</Trans>
            </Button>
            <ImageCropperModal onCancel={() => {
              setShowEditor(false);
            }} visible={showEditor} onCrop={(blob: Blob) => {
              onCrop(blob);
            }} image={cropperThumbnailURL} aspectRatio={16.0/9.0} />

        </Form>;
}
