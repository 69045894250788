import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Page } from "../../../theme/templates/page";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import EventImage from "../../event/components/event-image";
import { EventCard } from "../../event/components/event-card";
import { listAllEvents, listEventByCategory, listNextEventByCategory } from "../../event/event-slice";
import { Trans } from "react-i18next";
import "./home.css";
import { Link as ScrollLink , animateScroll as scroll } from "react-scroll";
import { getPlaformImage } from "../../../platforms/config";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from "../../../theme/components/loading";
import { LiveSelector } from "../../../theme/components/live-selector";
import { I18nLink } from "../../../theme/components/I18nLink";


const homeImage = getPlaformImage("home-viewers.png");

export default function Live() {
    const navigation = useNavigate();
    const user = useAppSelector((state) => state.login.user);
    const ready = useAppSelector((state) => state.app.ready);
    const events = useAppSelector((state) => state.events.events);
    const count = useAppSelector((state) => state.events.eventCount);
    const next = useAppSelector((state) => state.events.eventNext);
    const loading = useAppSelector((state) => state.events.loading);

    const dispatch = useAppDispatch();
    const categories = useAppSelector( (state) => state.events.categories) 

    const navigate = useNavigate(); 
    
    const {category, filter} = useParams();

    useEffect( () => {
        console.log(category);
        if (!category && !filter){
            dispatch(listEventByCategory({category: "all", filter: "live"}))
        }if (category){
            dispatch(listEventByCategory({category, filter}))
        }
    }, [category, filter]);

    return <Page className="home landing">
        <Container>
            <Row>
                <Col className="text-center">
                    <h2 id="discover-lives" className="discover-lives"><Trans i18nKey="home.user.header.discover_lives_title">Découvrir les lives !</Trans></h2>
                </Col>
            </Row> 
            <LiveSelector categories={categories} states={[]} category={category} filter={filter} loading={loading} events={events} count={count} next={next} onCategoryChange={(category) => {
                navigate(`/live/${category || "all"}/${filter}`, { preventScrollReset: true });
            }} onStateChange={(filter) => {
                navigate(`/live/${category || "all"}/${filter}`, { preventScrollReset: true });
            }} loadNext={(category, filter, offset) => {
                dispatch(listNextEventByCategory({category, filter, offset}))
            }}/>
                
            <Row className="creator-row">
                <Col md={8} className="creator">
                    <h2><Trans i18nKey="home.creator.footer.creator_title">Devenez Créateurs</Trans></h2>
                    <p><Trans i18nKey="home.creator.footer.creator_tagline">Plongez dans le monde du streaming live où votre créativité rencontre la monétisation. Partagez votre passion tout en monétisant votre contenu.</Trans></p>
                    <I18nLink to={"/creator"} className="creator-cta"><Button color="primary"><Trans i18nKey="home.creator.footer.call_to_action">Devenez créateur</Trans></Button></I18nLink>
                    <img style={{justifySelf:"stretch"}} src={getPlaformImage('home.png')}/>
                </Col>
            </Row>                             
                                          
        </Container>
    </Page>
  }   