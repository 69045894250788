import ChatMessage from "../dto/chat-message.dto";
import CreatorImage from "../../creator/components/creator-image";
import { ConnectedUser } from "../dto/connected-user.dto";
import { UserActionDropdown } from "./user-action-dropdown";

interface ChatMessageProps{
    me: ConnectedUser
    message : ChatMessage
    onClickAlert: Function
    onClickBan: Function
    onClickInvite: Function
    onClickPromote: Function
    user: ConnectedUser
}

export default function ChatMessageWidget(props: ChatMessageProps){


    return <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flex: 1}}>
        <div style={{width: 30, height: 30, borderRadius: 25, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CreatorImage style={{height: 30, maxWidth: "none"}} userName={props.message.userName} image={props.message.picture}/>
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 10}}>
            <h2 style={{color: "#8A8A8A", fontSize: 16, margin: 0, marginBottom: 1}}>{props.message.userName}</h2>
            <div style={{fontSize: 16, margin: 0, wordBreak : "break-all" }}>{props.message.message}</div>
        </div>
        <UserActionDropdown me={props.me} user={props.user} onClickAlert={props.onClickAlert} onClickBan={props.onClickBan} onClickInvite={props.onClickInvite} onClickPromote={props.onClickPromote} /> 
    </div>

}